import {useAuth} from "../../utils/auth/AuthContext";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Button} from "@nextui-org/react";
import {toast} from "react-toastify";
import {LoginResponse, loginUser} from "../../services/AuthService";

export default function LoginForm() {
    const {login} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleLogin = async () => {
        setLoading(true)
        try {
            const response = await loginUser({username, password})
            if (response.ok) {
                const data: LoginResponse = await response.json()
                login(data.access_token)
                navigate('/', {replace: true});
            } else {
                setPassword('')
                if (response.status === 401) {
                    toast.error("Se ha producido un error, por favor verifica usuario y contraseña...")
                }
            }

        } catch (error: any) {
            setPassword('')
            toast.error("Se ha producido un error, por favor verifica usuario y contraseña...")
        } finally {
            setLoading(false)
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter' &&
            username.trim().length > 0 &&
            password.trim().length > 0) {
            e.preventDefault();
            handleLogin().then();
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    alt="Logo"
                    src="/logo512.png"
                    className="block lg:hidden mx-auto h-32 w-auto opacity-20"/>

                <h2 className="block lg:hidden mt-6 text-center text-3xl font-semibold tracking-tight">
                    Panel para gestión de importación
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                <h2 className="text-2xl font-medium tracking-tight">
                    Acceso
                </h2>

                <form className="space-y-6" onKeyDown={handleKeyDown}>

                    <div className="mt-2 space-y-2">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            placeholder={"Usuario"}
                            required
                            autoComplete="off"
                            className="txt-input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />

                        <input
                            id="password"
                            name="password"
                            type="password"
                            placeholder={"Contraseña"}
                            required
                            autoComplete="current-password"
                            className="txt-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div>
                        <Button variant={'solid'}
                                color={'primary'}
                                className={'w-full'}
                                isLoading={loading}
                                isDisabled={username.trim().length === 0 || password.trim().length === 0}
                                onClick={handleLogin}>Iniciar sesión</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}