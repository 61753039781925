import React from "react";

export default function FileSkeleton() {
    return (
        <>
            {
                [{}, {}]
                    .map((value, i) => {
                        return (
                            <FileSkeletonRaw key={i}/>
                        )
                    })
            }
        </>
    )
}

//file card and list skeleton
function FileSkeletonRaw() {
    return (
        <>
            <div className={'skeleton-card flex-col p-4 space-y-4'}>

                <div className={'flex flex-row items-start space-x-4'}>
                    <div className={'flex-1 space-y-2'}>
                        <div className={'skeleton-row space-x-2'}>
                            <div className="skeleton-text w-20 h-6"></div>
                            <div className="skeleton-text w-24 h-6"></div>

                        </div>
                        <div className={'skeleton-row space-x-2'}>
                            <div className="skeleton-text w-32 h-6"></div>
                            <div className="skeleton-text w-24 h-6"></div>

                        </div>
                    </div>
                    <div className="skeleton-text w-24 h-6"></div>
                </div>

                <hr/>

                <div className="skeleton-text w-12 h-6"></div>

                {
                    [{}, {}, {}]
                        .map((value, i) => {
                            return (
                                <div key={i}
                                     className={'skeleton-row space-x-4 border-b last:border-none pb-2'}>
                                    <div className="skeleton-text w-[18px] h-[18px]"></div>
                                    <div className="skeleton-text w-56 h-6 flex-1"></div>
                                    <div className="skeleton-text w-28 h-6"></div>
                                </div>
                            )
                        })
                }

            </div>
        </>
    )
}