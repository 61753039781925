import {CheckCircle, CircleX, FilePlus2, UploadCloud} from "lucide-react";
import {Button, Select, SelectItem} from "@nextui-org/react";
import React from "react";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure} from "@nextui-org/modal";
import {fees} from "./LosesFilters";
import {toast} from "react-toastify";
import {uploadLosesFileRaw} from "../../services/LosesService";

export default function UploadLosesFileDialog() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    //file to upload
    const [rawFile, setRawFile] = React.useState<File | undefined>(undefined);
    //selected file from pc
    const [selectedFile, setSelectedFile] = React.useState<string | undefined>(undefined);
    //uploading state
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    //notify when file it's uploaded
    const [fileUploaded, setFileUploaded] = React.useState<boolean | undefined>(undefined);
    //input file
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);

    //get and set excel file
    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setRawFile(file)
            setSelectedFile(URL.createObjectURL(file));
        }
    };

    const [selectedFee, setSelectedFee] = React.useState<string>("");
    const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => setSelectedFee(e.target.value);

    //upload excel file to server or notify error
    const handleUpload = async () => {
        if (rawFile) {
            setIsUploading(true)
            try {
                const response = await uploadLosesFileRaw(selectedFee, rawFile);
                const ok = response.ok
                setFileUploaded(ok)
                setIsUploading(false)
            } catch (error) {
                setIsUploading(false)
                toast.error("Error al importar el archivo...")
            }
        }
    };

    return (
        <>
            <Button startContent={<FilePlus2/>}
                    color={'primary'}
                    onClick={onOpen}>Importar archivo</Button>

            <Modal
                isDismissable={false}
                isOpen={isOpen}
                onOpenChange={() => {
                    //reset data on close dialog
                    setSelectedFile(undefined);
                    setRawFile(undefined)
                    setIsUploading(false)
                    setFileUploaded(undefined)
                    setSelectedFee("")
                    onOpenChange()
                }}>
                <ModalContent className={`sm:max-w-[425px] min-w-[600px]`}>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Importar archivo</ModalHeader>
                            <ModalBody>

                                {/**when file it's imported*/}
                                {fileUploaded && <div className={'flex flex-col items-center space-y-2'}>
                                    <CheckCircle size={32} color={'green'}/>
                                    <p>Archivo importado</p>
                                </div>}
                                {/**when some error happens*/}
                                {!fileUploaded && fileUploaded !== undefined &&
                                    <div className={'flex flex-col items-center space-y-2'}>
                                        <CircleX size={32} color={'red'}/>
                                        <p>Error al importar el archivo</p>
                                    </div>}
                                {!isUploading && fileUploaded === undefined &&
                                    <div className={'flex flex-col space-y-4'}>

                                        <div
                                            className={'flex flex-col bg-orange-100 text-orange-500 border-[0.5px] border-orange-500 border-opacity-50 p-2 rounded-lg'}>
                                            <p className={'text-sm font-semibold'}>Subir los ficheros individualmente
                                                sin modificar su nombre</p>
                                            <p className={'text-sm'}>Ejem: C2_perd20TD_20241001_20241031</p>
                                        </div>

                                        <Button startContent={<FilePlus2/>}
                                                color={'primary'}
                                                onClick={() => inputFileRef.current?.click()}>{rawFile === undefined ? 'Seleccionar fichero' : rawFile?.name}</Button>

                                        <input type="file"
                                               className="sr-only"
                                               ref={inputFileRef}
                                               onChange={handleFileChange}/>

                                        <Select
                                            label="Tarifas"
                                            placeholder="Selecciona la tarifa"
                                            selectedKeys={[selectedFee]}
                                            onChange={handleSelectionChange}>
                                            {fees.map((fee) => (
                                                <SelectItem key={fee.value}>{fee.title}</SelectItem>
                                            ))}
                                        </Select>
                                    </div>
                                }

                            </ModalBody>
                            <ModalFooter className={`justify-between`}>
                                <Button variant='flat' onPress={onClose}>{fileUploaded ? 'Ok' : 'Cancelar'}</Button>
                                {!fileUploaded &&
                                    <Button
                                        startContent={<UploadCloud/>}
                                        color={'primary'}
                                        isLoading={isUploading}
                                        isDisabled={isUploading || selectedFee.trim().length === 0 ||
                                            rawFile === undefined ||
                                            selectedFile === undefined}
                                        onPress={handleUpload}>
                                        Importar
                                    </Button>}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}