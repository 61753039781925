import {Button, Spinner} from "@nextui-org/react";
import {CheckCircle, CircleX, CloudUpload, FilePlus2, UploadCloud} from "lucide-react";
import React from "react";
import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@nextui-org/modal";
import {uploadFileRaw} from "../../services/ProfilesService";
import {toast} from "react-toastify";

export default function ImportFileButton() {
    //file to upload
    const [excelFile, setExcelFile] = React.useState<File | undefined>(undefined);
    //selected file from pc
    const [selectedFile, setSelectedFile] = React.useState<string | undefined>(undefined);
    //uploading state
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    //notify when file it's uploaded
    const [fileUploaded, setFileUploaded] = React.useState<boolean | undefined>(undefined);
    //input file
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);

    //get and set excel file
    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setExcelFile(file)
            setSelectedFile(URL.createObjectURL(file));
        }
    };

    //upload excel file to server or notify error
    const handleUpload = async () => {
        if (excelFile) {
            setIsUploading(true)
            try {
                const response = await uploadFileRaw(excelFile);
                const ok = response.ok
                setFileUploaded(ok)
                setIsUploading(false)
            } catch (error) {
                setIsUploading(false)
                toast.error("Error al importar el archivo...")
            }
        }
    };

    return (
        <>
            {/**Import excel file*/}
            <Button startContent={<FilePlus2/>}
                    color={'primary'}
                    onClick={() => inputFileRef.current?.click()}>Importar archivo</Button>

            {/**Import excel file configuration*/}
            <input type="file"
                   className="sr-only"
                   ref={inputFileRef}
                   accept={'.xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                   onChange={handleFileChange}/>

            <Modal
                isDismissable={false}
                isOpen={excelFile !== undefined && selectedFile !== undefined}
                onOpenChange={() => {
                    //reset data on close dialog
                    setExcelFile(undefined)
                    setSelectedFile(undefined);
                }}>
                <ModalContent className={`sm:max-w-[425px] min-w-[600px]`}>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Importar archivo</ModalHeader>
                            <ModalBody>
                                {/**icon and file name*/}
                                {!isUploading && fileUploaded === undefined &&
                                    <div className={'flex flex-col items-center space-y-2'}>
                                        <CloudUpload size={32}/>
                                        <p>{excelFile?.name}</p>
                                    </div>}
                                {/**progress*/}
                                {isUploading && <Spinner label={'Importando archivo...'}/>}
                                {/**when file it's imported*/}
                                {fileUploaded && <div className={'flex flex-col items-center space-y-2'}>
                                    <CheckCircle size={32} color={'green'}/>
                                    <p>Archivo importado</p>
                                </div>}
                                {/**when some error happens*/}
                                {!fileUploaded && fileUploaded !== undefined &&
                                    <div className={'flex flex-col items-center space-y-2'}>
                                        <CircleX size={32} color={'red'}/>
                                        <p>Error al importar el archivo</p>
                                    </div>}
                            </ModalBody>
                            <ModalFooter className={`justify-between`}>
                                <Button variant='flat' onPress={onClose}>{fileUploaded ? 'Ok' : 'Cancelar'}</Button>
                                {!fileUploaded &&
                                    <Button
                                        startContent={<UploadCloud/>}
                                        color={'primary'}
                                        isLoading={isUploading}
                                        isDisabled={isUploading}
                                        onPress={handleUpload}>
                                        Importar
                                    </Button>}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
