import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@nextui-org/modal";
import {Button, Spinner} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {ILog} from "../../models/i.log";
import {getAppLogs} from "../../services/LogsService";
import {getDMYHMDate} from "../../utils/date-utils";
import {toast} from "react-toastify";

interface AppLogsProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function AppLogsDialog({isOpen, onClose}: AppLogsProps) {
    //app logs and set logs state
    const [logs, setLogs] = useState<ILog[]>([]);
    //loading state
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //get app logs on create component
    useEffect(() => {
        const fetchLogs = async () => {
            setIsLoading(true)
            const response: Response = await getAppLogs();
            if (response.ok) {
                const data: ILog[] = await response.json()
                setLogs(data);
            } else {
                toast.error("Error al intentar obtener los logs...")
            }
            setIsLoading(false)
        };

        fetchLogs().then();
    }, []);

    return (
        <Modal
            isDismissable={false}
            isOpen={isOpen}
            onOpenChange={onClose}>
            <ModalContent className={`sm:max-w-[425px] min-w-[600px] min-h-[400px] max-h-[400px]`}>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Logs</ModalHeader>
                        <ModalBody>
                            {isLoading &&
                                <div className={'flex flex-1 justify-center'}>
                                    <Spinner/>
                                </div>}

                            {!isLoading && logs.length === 0 && <p>Sin logs</p>}

                            {!isLoading && logs.length > 0 &&
                                <div>
                                    <hr/>
                                    {/*list app logs*/}
                                    <div className={'max-h-[250px] overflow-scroll scrollbar-hide'}>
                                        {logs
                                            .sort((a, b) =>
                                                new Date(b.date).getTime() - new Date(a.date).getTime())
                                            .map((l, i) => {
                                                return <div key={i}
                                                            className={'flex flex-col border-b last:border-none py-2'}>
                                                    <span className="text-sm flex-1 break-words">{l.message}</span>
                                                    <span
                                                        className="text-sm text-slate-400 whitespace-nowrap text-end">{getDMYHMDate(l.date)}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <hr/>
                                </div>}
                        </ModalBody>
                        <ModalFooter className={`justify-between`}>
                            <Button variant='flat' onPress={onClose}>Ok</Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}