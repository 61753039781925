import {useProfileStore} from "../../store/ProfileStore";
import TableSkeleton from "../skeletons/TableSkeleton";
import React from "react";

export default function TableProfiles() {
    //access to profiles and loading state from zustand store
    const {profiles, isLoading} = useProfileStore();
    return (
        <>
            {isLoading ? <TableSkeleton numColumns={6} numRows={6}/> :
                <div className={'flex flex-col space-y-4'}>
                    <table className="table">
                        <thead>
                        <tr className="table-tr">
                            <th className="table-th rounded-tl-lg">Fecha</th>
                            <th className="table-th">Hora</th>
                            <th className="table-th">Perfil A</th>
                            <th className="table-th">Perfil B</th>
                            <th className="table-th">Perfil C</th>
                            <th className="table-th rounded-tr-lg">Perfil D</th>
                        </tr>
                        </thead>
                        {!isLoading && profiles.length > 0 &&
                            <tbody>
                            {profiles.map((profile, index) => (
                                <tr key={index}>
                                    <td className="table-td whitespace-nowrap">{profile.fecha}</td>
                                    <td className="table-td whitespace-nowrap">{profile.hora_dia}</td>
                                    <td className="table-td whitespace-nowrap">{profile.perfil_a}</td>
                                    <td className="table-td whitespace-nowrap">{profile.perfil_b}</td>
                                    <td className="table-td whitespace-nowrap">{profile.perfil_c}</td>
                                    <td className="table-td whitespace-nowrap">{profile.perfil_d}</td>
                                </tr>
                            ))}
                            </tbody>
                        }
                    </table>
                    {/**show text when profiles it's empty*/}
                    {!isLoading && profiles.length === 0 && <div>
                        <p className={'flex items-center justify-center font-semibold text-slate-400'}>Sin datos</p>
                    </div>}

                </div>
            }
        </>
    )
}