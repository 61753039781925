import {create} from 'zustand';
import {ITask} from "../models/i.task";
import {getTasks} from "../services/TaskService";

export type LastTaskState = {
    lastTask: ITask | undefined;
    isTaskInitialized: boolean | undefined,
    isLoading: boolean;
    error?: string;
    fetchTasksToCheckLastTask: () => Promise<void>;
}

/**
 * store all tasks with fetch request, error and loading state
 */
export const useLastTaskStore = create<LastTaskState>((set) => ({
    lastTask: undefined,
    isTaskInitialized: undefined,
    isLoading: false,
    error: undefined,
    fetchTasksToCheckLastTask: async () => {
        set({isLoading: true, error: undefined})
        try {
            const response = await getTasks({page: 1, limit: 1})
            if (response.ok) {
                const tasks: ITask[] = await response.json()
                if (tasks.length === 0) {
                    set({isLoading: false, isTaskInitialized: false})
                } else {
                    const task = tasks[0]
                    set({isLoading: false, isTaskInitialized: task && task.status === 'in_progress'})
                }
            } else {
                set({isLoading: false, error: 'Error al obtener la ultima tarea...',})
            }
        } catch (error: any) {
            set({isLoading: false, error: error.message || 'Error getting the last task...'})
        }
    }
}))