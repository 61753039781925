import React from "react";
import UploadLosesFileDialog from "../components/loses/UploadLosesFileDialog";
import LosesFilters from "../components/loses/LosesFilters";
import TableLoses from "../components/loses/TableLoses";
import {useLosesStore} from "../store/LosesStore";

export default function LosesPage() {
    const {isDatesSelected} = useLosesStore();
    return (
        <div className={'main-section overflow-scroll scrollbar-hide'}>
            <div
                className={'bg-white flex flex-row h-[56px] rounded-md shadow-md px-4 items-center justify-between'}>

                <div className={'flex flex-row items-center space-x-4'}>
                    <h2 className="text-2xl font-bold tracking-tight">Pérdidas</h2>
                </div>

                <div className={'flex flex-row items-center space-x-4'}>
                    <UploadLosesFileDialog/>
                </div>
            </div>

            <div className={'flex flex-col bg-white w-full p-4 rounded-md shadow-md space-y-4'}>

                <div className={'flex flex-row w-full justify-between space-x-2'}>
                    <LosesFilters/>
                </div>

                {/*separation line*/}
                <hr/>

                {/*show hint text to select start-end dates*/}
                {!isDatesSelected &&
                    <div className={'text-center'}>
                        <p className={'font-semibold tracking-tight text-slate-400'}>Selecciona fecha inicio, fecha
                            fin y tarifa
                            para consultar los
                            datos.</p>
                    </div>}
                {/*show table profiles when use select start-end dates*/}
                {isDatesSelected &&
                    <div className={'overflow-scroll scrollbar-hide'}>
                        <TableLoses/>
                    </div>}
            </div>

        </div>
    )
}