import {Navigate, Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./utils/auth/ProtectedRoute";
import ImportsPage from "./pages/ImportsPage";
import ImportDetailPage from "./pages/ImportDetailPage";
import ProfilesPage from "./pages/ProfilesPage";
import NotFoundPage from "./pages/NotFoundPage";
import React from "react";
import Layout from "./components/Layout/Layout";
import LosesPage from "./pages/LosesPage";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path={"/"} element={<Navigate to={"/imports"} replace/>}></Route>
                <Route path={"/imports"} element={<ProtectedRoute><ImportsPage/></ProtectedRoute>}></Route>
                <Route path={"/imports/:id"} element={<ProtectedRoute><ImportDetailPage/></ProtectedRoute>}></Route>
                <Route path={"/profiles"} element={<ProtectedRoute><ProfilesPage/></ProtectedRoute>}></Route>
                <Route path={"/loses"} element={<ProtectedRoute><LosesPage/></ProtectedRoute>}></Route>
                <Route path={"*"} element={<NotFoundPage/>}></Route>
            </Route>
            <Route path={"/login"} element={<LoginPage/>}></Route>
        </Routes>
    )
}