import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';

interface AuthContextType {
    isLoggedIn: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
        const token = localStorage.getItem('accessToken');
        return token !== null && !isTokenExpired(token);
    });

    /**
     * check if user it's logged in
     */
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setIsLoggedIn(!isTokenExpired(token));
        }
    }, []);

    /**
     * save access token and show main screen
     * @param token
     */
    const login = (token: string) => {
        localStorage.setItem('accessToken', token);
        setIsLoggedIn(true);
    };

    /**
     * remove access token and show login screen
     */
    const logout = () => {
        localStorage.removeItem('accessToken');
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider value={{isLoggedIn, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthContext');
    }
    return context;
};

/**
 * check if .exp from jwt token has expired
 * @param token
 */
const isTokenExpired = (token: string): boolean => {
    try {
        const base64Payload = token.split('.')[1];
        const payload = JSON.parse(atob(base64Payload));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
    } catch (error) {
        return true;
    }
};