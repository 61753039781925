import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from "./AuthContext";

interface ProtectedRouteProps {
    children: JSX.Element;
}

/**
 * protect the routes and navigate to login page
 * @param children
 * @constructor
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const {isLoggedIn} = useAuth();

    if (!isLoggedIn) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

export default ProtectedRoute;
