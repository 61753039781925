import {ListRestart, Pause, Play} from "lucide-react";
import TableImportsComponent from "../components/imports/TableImports";
import MenuButton from "../components/imports/MenuButton";
import {Button, Tooltip} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import {usePaginationStore} from "../store/PaginationStore";
import {useTasksStore} from "../store/TasksStore";
import {startTask, stopTask, StopTaskResult} from "../services/TaskService";
import NextDate from "../components/imports/NextDate";
import {toast} from "react-toastify";
import {useLastTaskStore} from "../store/LastTaskStore";
import DownloadTemplateButton from "../components/profiles/DownloadTemplateButton";
import ImportFileButton from "../components/profiles/ImportFileButton";

export default function ImportsPage() {
    //get tasks and fetch tasks method
    const {fetchTasks} = useTasksStore();
    //check if task is initialized and fetch las task
    const {isTaskInitialized, fetchTasksToCheckLastTask} = useLastTaskStore();
    //show-hide change status modal
    const [isChangeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
    //current page
    const {page, setPage} = usePaginationStore();

    useEffect(() => {
        fetchTasksToCheckLastTask().then()
    }, []);

    //get tasks when page changes
    useEffect(() => {
        getTasks()
    }, [page]);

    //get tasks with page and limit page
    function getTasks() {
        fetchTasks({page: page, limit: 15}).then()
    }

    //stop current task
    const handleStartTask = async () => {
        /**
         const response = await startTask();
         if (response.ok) {
         const result: StopTaskResult = await response.json()
         if (result.message === 'Task created') {
         toast("Tarea iniciada")
         fetchTasksToCheckLastTask().then()
         setPage(1)
         getTasks()
         }
         } else {
         const result: ErrorTask = await response.json()
         toast.error(result.detail)
         }*/

        try {
            const resp = startTask()
            resp.then((response) => {
                fetchTasksToCheckLastTask().then()
                setPage(1)
                setTimeout(() => getTasks(), 2000)
            }).catch((e) => {
                //console.error(e)
            })
            await toast.promise(
                resp,
                {
                    pending: 'Iniciando tarea...',
                    success: 'Tarea iniciada.',
                    error: {
                        render: (error: any) => {
                            return error.data.message
                        },
                    },
                }
            )
        } catch (error: any) {
            //toast.error(error.message);
        }
    }

    //stop current task
    const handleStopTask = async () => {
        toast.warning("Se procede a parar la tarea. Puede llevar varios minutos.")
        const response = await stopTask();
        if (response.ok) {
            const result: StopTaskResult = await response.json()
            if (result.message === 'Task stopped') {
                fetchTasksToCheckLastTask().then()
                setPage(1)
                setTimeout(() => getTasks(), 2000)
            }
        } else {
            toast.error("Error al intentar parar la tarea...")
        }
    }

    return (
        <div>

            {/**
             <div className={'toolbar'}>


             <div>
             <img
             alt="Logo"
             src="/logos/logo_ausarta_transparent.png"
             className="mx-auto h-8 w-auto"/>
             </div>

             <div className={'toolbar-child-row'}>

             <>

             {isChangeStatusModalOpen && (
             <ConfirmationDialog
             confirmationText={"Sí"}
             confirmationColor={"primary"}
             isOpen={isChangeStatusModalOpen}
             onClose={() => setChangeStatusModalOpen(false)}
             onConfirm={() => {
             setChangeStatusModalOpen(false)
             if (isTaskInitialized) {
             handleStopTask().then();
             } else {
             handleStartTask().then()
             }
             }}
             title={"Estado"}
             message={isTaskInitialized ? '¿Desea parar la tarea?' : '¿Desea iniciar la tarea?'}
             />
             )}


             {isTaskInitialized !== undefined &&
             <Button startContent={isTaskInitialized ? <Pause size={16}/> : <Play size={16}/>}
             variant={isTaskInitialized ? 'flat' : 'solid'}
             color={isTaskInitialized ? 'warning' : 'primary'}
             onClick={() => setChangeStatusModalOpen(true)}>
             {isTaskInitialized ? 'Parar' : 'Iniciar'}</Button>}
             </>


             <MenuButton/>
             </div>

             </div>
             */}

            {/*Main section*/}
            <div className={'main-section'}>

                <div
                    className={'bg-white flex flex-row h-[56px] rounded-md shadow-md px-4 items-center justify-between'}>

                    <div className={'flex flex-row items-center space-x-4'}>
                        <h2 className="text-2xl font-bold tracking-tight">Importación</h2>
                    </div>

                    <div className={'flex flex-row items-center space-x-4'}>
                        <>

                            {isChangeStatusModalOpen && (
                                <ConfirmationDialog
                                    confirmationText={"Sí"}
                                    confirmationColor={"primary"}
                                    isOpen={isChangeStatusModalOpen}
                                    onClose={() => setChangeStatusModalOpen(false)}
                                    onConfirm={() => {
                                        setChangeStatusModalOpen(false)
                                        if (isTaskInitialized) {
                                            handleStopTask().then();
                                        } else {
                                            handleStartTask().then()
                                        }
                                    }}
                                    title={"Estado"}
                                    message={isTaskInitialized ? '¿Desea parar la tarea?' : '¿Desea iniciar la tarea?'}
                                />
                            )}

                            {isTaskInitialized !== undefined &&
                                <Button startContent={isTaskInitialized ? <Pause size={16}/> : <Play size={16}/>}
                                        variant={isTaskInitialized ? 'flat' : 'solid'}
                                        color={isTaskInitialized ? 'warning' : 'primary'}
                                        onClick={() => setChangeStatusModalOpen(true)}>
                                    {isTaskInitialized ? 'Parar' : 'Iniciar'}</Button>}
                        </>
                    </div>
                </div>

                {/*next date component*/}
                <NextDate/>

                {/*Imports card*/}
                <div className={'flex flex-col bg-white w-full p-4 rounded-md shadow-md space-y-4'}>

                    <div className={'flex flex-row w-full justify-between items-center space-x-2'}>
                        <h2 className="card-title">Historico</h2>
                        {/*button refresh data*/}
                        <Tooltip content="Actualizar datos">
                            <Button startContent={<ListRestart/>}
                                    variant={'light'}
                                    isIconOnly
                                    color={'primary'}
                                    onClick={() => {
                                        fetchTasksToCheckLastTask().then()
                                        setPage(1)
                                        getTasks()
                                    }}
                            ></Button>
                        </Tooltip>
                    </div>

                    {/*separation line*/}
                    <hr/>

                    {/*table imports component*/}
                    <div className={'overflow-scroll scrollbar-hide'}>
                        <TableImportsComponent/>
                    </div>
                </div>

            </div>

        </div>
    )
}