import {create} from 'zustand';

export type PaginationState = {
    page: number;
    hideButtonNextPage: boolean;
    setHideButtonNextPage: (show: boolean) => void;
    setPage: (page: number) => void;
}

/**
 * store and set new page from Pagination component
 */
export const usePaginationStore = create<PaginationState>((set) => ({
    page: 1,
    hideButtonNextPage: true,
    setHideButtonNextPage: (show: boolean) => set(() => ({hideButtonNextPage: show})),
    setPage: (page: number) => set(() => ({page})),
}))