import {useTaskStore} from "../../store/TaskStore";
import {getDMYHMDate} from "../../utils/date-utils";
import {getTaskStatus} from "../../utils/task-utils";
import {CircleX, Info} from "lucide-react";
import React from "react";
import FileSkeleton from "../skeletons/FileSkeleton";

export default function ImportsFiles() {
    //selected task and loading state
    const {task, isLoading} = useTaskStore();

    return (
        <>
            {/**show file skeleton or list*/}
            {isLoading ? <FileSkeleton/> :
                task && <div className={'xl:grid xl:grid-cols-2 xl:gap-4 space-y-4 xl:space-y-0'}>
                    {task.files.map((file, i) => {
                        return <div key={i}
                                    className={'flex flex-col bg-white w-full p-4 rounded-md shadow-md space-y-4 overflow-hidden'}>

                            <div className={'flex flex-row items-start space-x-4'}>
                                <div className={'flex-1'}>
                                    <div className={'flex flex-row items-center space-x-2'}>
                                        <h2 className="card-title">Fichero:</h2>
                                        <span>{file.name}</span>
                                    </div>
                                    <div className={'flex flex-row items-center space-x-2'}>
                                        <h2 className="card-title">Fecha
                                            importado:</h2>
                                        <span>{file.start_date !== null ? getDMYHMDate(file.start_date) : ''}</span>
                                    </div>
                                    <div className={'flex flex-row items-center space-x-2'}>
                                        <h2 className="card-title">Filas importadas:</h2>
                                        <span>{file.rows_imported}</span>
                                    </div>
                                </div>
                                <span className={`import-status ${file.status} whitespace-nowrap w-min text-sm`}>
                            {getTaskStatus(file.status)}</span>
                            </div>

                            <hr/>

                            <h2 className="card-title">Logs</h2>
                            {/**logs list*/}
                            {
                                [...file.logs.map(log => ({...log, type: 'log'})),
                                    ...file.errors.map(error => ({...error, type: 'error'}))]
                                    .sort((a, b) =>
                                        new Date(b.date).getTime() - new Date(a.date).getTime())
                                    .map((log, i) => {
                                        return (
                                            <div key={i}
                                                 className={'flex flex-row space-x-4 border-b last:border-none items-center pb-2'}>
                                                {log.type === 'error' ?
                                                    <CircleX className={'shrink-0'} size={18} color={'red'}/> :
                                                    <Info className={'shrink-0'} size={18} color={'green'}/>}
                                                <span className="text-sm flex-1">{log.message}</span>
                                                <span className="text-sm text-slate-400">{getDMYHMDate(log.date)}</span>
                                            </div>
                                        )
                                    })
                            }

                        </div>
                    })}

                </div>}

        </>
    )
}