import React from "react";
import {DateValue, getLocalTimeZone} from "@internationalized/date";
import {useProfileStore} from "../../store/ProfileStore";
import {toast} from "react-toastify";
import {convertToDateValue, toLocalISOString} from "../../utils/date-utils";
import {DatePicker} from "@nextui-org/react";

export default function DatesSelector() {
    //fetch profiles method to zustand store
    const {fetchProfiles} = useProfileStore();
    //start and end selected dates
    const [startDate, setStartDate] = React.useState<DateValue | undefined>(undefined)
    const [endDate, setEndDate] = React.useState<DateValue | undefined>(undefined)

    //call this when start and end dates changes and fetch profiles or show error message
    React.useEffect(() => {
        if (startDate && endDate) {
            const start = startDate.toDate(getLocalTimeZone());
            const end = endDate.toDate(getLocalTimeZone());

            if (end < start) {
                toast.error("La fecha de fin no puede ser inferior a la fecha de inicio.");
                return;
            }

            if (start > end) {
                toast.error("La fecha de inicio no puede ser mayor a la fecha de fin.");
                return;
            }

            // Calculate the difference in months
            const monthDiff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

            if (monthDiff <= 2) {
                fetchProfiles(toLocalISOString(start), toLocalISOString(end)).then();
            } else {
                toast.error("La diferencia entre las fechas puede ser de máximo 2 meses.");
            }
        }
    }, [startDate, endDate]);

    //set today date y default
    React.useEffect(() => {
        const now = new Date();
        setStartDate(convertToDateValue(now));
        setEndDate(convertToDateValue(now));
    }, []);

    return (
        <div className={'flex flex-row items-center space-x-2 flex-1'}>
            <DatePicker label="Fecha inicio" isRequired className="max-w-[184px]"
                        value={startDate} onChange={setStartDate}/>
            <DatePicker label="Fecha fin" isRequired className="max-w-[184px]"
                        value={endDate} onChange={setEndDate}/>
        </div>
    )
}