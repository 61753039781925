import {create} from 'zustand';
import {ITask} from "../models/i.task";
import {getTask} from "../services/TaskService";

export type TaskState = {
    task?: ITask;
    isLoading: boolean;
    error?: string;
    fetchTask: (taskId: string) => Promise<void>;
}

/**
 * store selected task with fetch request, error and loading state
 */
export const useTaskStore = create<TaskState>((set) => ({
    selectedTask: undefined,
    isLoading: false,
    error: undefined,
    fetchTask: async (taskId: string) => {
        set({isLoading: true, error: undefined})
        try {
            const response: Response = await getTask(taskId)
            if (response.ok) {
                const task: ITask = await response.json()
                set({isLoading: false, task})
            } else {
                const message = response.status === 404 ? 'Esta tarea no existe' :
                    'Error al obtener los detalles'
                set({isLoading: false, error: message, task: undefined})
            }
        } catch (error: any) {
            set({isLoading: false, error: 'Error al obtener los detalles'})
        }
    }
}))