import {useNavigate, useParams} from "react-router-dom";
import {ArrowLeft, ListRestart} from "lucide-react";
import {Button, Tooltip} from "@nextui-org/react";
import React, {useEffect} from "react";
import {useTaskStore} from "../store/TaskStore";
import ImportsFiles from "../components/imports_details/ImportsFiles";
import ErrorGeneral from "../components/shared/ErrorGeneral";

export default function ImportDetailPage() {
    //get task id from url
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    //get data from zustand
    const {task, isLoading, fetchTask, error} = useTaskStore();

    //get task every time id changes
    useEffect(() => getTask(), [fetchTask, id]);

    //get task when id exists
    function getTask() {
        if (id) fetchTask(id).then();
    }

    return (
        <div>

            <div className={'toolbar rounded-md mx-4 mt-4'}>

                <div className={'toolbar-child-row'}>
                    <Button startContent={<ArrowLeft/>}
                            variant={'light'}
                            isIconOnly
                            color={'primary'}
                            onClick={() => navigate('/imports')}></Button>

                    <>
                        {/*Show task name or loading skeleton*/}
                        {isLoading ? <h2 className="bg-gray-300 rounded-full w-36 h-6 animate-pulse"></h2> :
                            <h2 className="toolbar-title">{task?.name}</h2>
                        }
                    </>
                </div>

                {/*button refresh data*/}
                <Tooltip content="Actualizar datos">
                    <Button startContent={<ListRestart/>}
                            variant={'light'}
                            isIconOnly
                            color={'primary'}
                            onClick={() => getTask()}></Button>
                </Tooltip>

            </div>

            {/*Main section*/}
            <div className={'main-section overflow-scroll scrollbar-hide'}>
                {/*imports table*/}
                <ImportsFiles/>
                {/*Show error if exists*/}
                {error && <ErrorGeneral error={error}/>}
            </div>

        </div>
    )
}