import {CircleX} from "lucide-react";
import React from "react";

interface ErrorGeneralProps {
    error: string;
}

export default function ErrorGeneral({error}: ErrorGeneralProps) {
    return (
        <div className={'flex flex-col items-center justify-center space-y-12'}>
            <div className={'flex flex-col items-center space-y-2'}>
                <CircleX size={86} color={'red'}/>
                <span className={'tracking-tight font-semibold text-slate-400'}>{error}</span>
            </div>
        </div>
    )
}