import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Pagination from "../shared/Pagination";
import {useTasksStore} from "../../store/TasksStore";
import {calculateDatesHrs, getDMYHMDate} from "../../utils/date-utils";
import {getTaskStatus, getTaskType} from "../../utils/task-utils";
import TableSkeleton from "../skeletons/TableSkeleton";
import {usePaginationStore} from "../../store/PaginationStore";

export default function TableImportsComponent() {
    //tasks list and loading state from zustand store
    const {tasks, isLoading} = useTasksStore();
    const {setHideButtonNextPage} = usePaginationStore()
    //to navigate to details screen
    const navigate = useNavigate();

    //enable-disable button next page from pagination component
    useEffect(() => {
        setHideButtonNextPage(tasks.length === 0)
    }, [tasks]);

    return (
        <>
            {/**show skeleton or tasks table*/}
            {
                isLoading ? <TableSkeleton numColumns={5} numRows={3}/> :

                    <div className={'flex flex-col space-y-4'}>
                        <table className="table">
                            <thead>
                            <tr className="table-tr">
                                <th className="table-th rounded-tl-lg">Nombre</th>
                                <th className="table-th">Fecha fichero importada</th>
                                <th className="table-th">Tiempo ejecución</th>
                                <th className="table-th">Tipo</th>
                                <th className="table-th rounded-tr-lg">Estado</th>
                            </tr>
                            </thead>
                            {!isLoading && tasks.length > 0 &&
                                <tbody>
                                {tasks.map((task) => (
                                    <tr key={task._id}
                                        className="hover:bg-gray-50 cursor-pointer"
                                        onClick={() => navigate(`/imports/${task._id}`)}>
                                        <td className="table-td">{task.name}</td>
                                        <td className="table-td">{getDMYHMDate(task.start_date)}</td>
                                        <td className="table-td">{task.end_date == null ?
                                            calculateDatesHrs(task.start_date, new Date().toISOString()) :
                                            calculateDatesHrs(task.start_date, task.end_date)}</td>
                                        <td className="table-td">{getTaskType(task.type)}</td>
                                        <td className="table-td"><span
                                            className={`import-status ${task.status} whitespace-nowrap`}>{getTaskStatus(task.status)}</span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            }
                        </table>
                        {/**show when has no data*/}
                        {!isLoading && tasks.length === 0 && <div>
                            <p className={'flex items-center justify-center font-semibold text-slate-400'}>Sin datos</p>
                        </div>}
                        {/**pagination buttons component*/}
                        <Pagination/>
                    </div>
            }
        </>
    );
};


