import LoginForm from "../components/login/LoginForm";

export default function LoginPage() {

    return (<>
        {/*show only when screen it's >=lg*/}
        <div className={'hidden lg:block'}>
            {/*show logo section and login form 50/50*/}
            <div className={'flex flex-row'}>
                <div className={'flex flex-col c-green-light flex-1 justify-center h-screen'}>
                    <img
                        alt="Logo"
                        src="/logo512.png"
                        className="mx-auto h-32 w-auto opacity-20 mb-4"/>
                    <h2 className="text-center text-3xl font-semibold tracking-tight">
                        Panel para gestión de importación
                    </h2>
                </div>
                <div className={'flex flex-row flex-1 bg-white'}>
                    <LoginForm/>
                </div>
            </div>
        </div>
        {/*show only when screen it's <lg*/}
        <div className={'block lg:hidden h-screen bg-white'}>
            <LoginForm/>
        </div>
    </>)
}