import {
    Link,
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle
} from "@nextui-org/react";
import React from "react";
import {useLocation} from "react-router-dom";
import MenuButton from "../imports/MenuButton";

export default function ToolBar() {
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path;
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const menuItems = [
        {
            title: "Importación",
            link: "/imports"
        },
        {
            title: "Perfiles",
            link: "/profiles"
        },
        {
            title: "Pérdidas",
            link: "/loses"
        }
    ];

    return (
        <>
            <Navbar maxWidth={'full'} className={'bg-white shadow-md'} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden"
                    />
                    <NavbarBrand>
                        <img
                            alt="Logo"
                            src="/logos/logo_ausarta_transparent.png"
                            className="h-8 w-auto"/>
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    {menuItems.map((item, index) => (
                        <NavbarItem key={`${item}-${index + 1}`} isActive={isActive(item.link)}>
                            <Link
                                color={isActive(item.link) ? "primary" : "foreground"}
                                className={`px-2 py-1 rounded-md ${isActive(item.link) && "c-green-light"}`}
                                href={item.link}>
                                {item.title}</Link>
                        </NavbarItem>
                    ))}
                </NavbarContent>

                <NavbarContent justify="end">
                    <NavbarItem>
                        <MenuButton/>
                    </NavbarItem>
                </NavbarContent>

                <NavbarMenu>
                    {menuItems.map((item, index) => (
                        <NavbarMenuItem key={`${item}-${index}`} isActive={isActive(item.link)}>
                            <Link
                                color={isActive(item.link) ? "primary" : "foreground"}
                                className={`w-full px-2 py-1 rounded-md ${isActive(item.link) && "c-green-light"}`}
                                href={item.link}
                                size="lg">
                                {item.title}
                            </Link>
                        </NavbarMenuItem>
                    ))}
                </NavbarMenu>
            </Navbar>
        </>
    );
}
