import {useEffect, useState} from "react";
import NextDateSkeleton from "../skeletons/NextDateSkeleton";
import {getDMYHMDate} from "../../utils/date-utils";
import {Clock} from "lucide-react";
import {getNextRunTime, NextRunTime} from "../../services/TaskService";
import {toast} from "react-toastify";

export default function NextDate() {
    //for loading indicator
    const [isLoadingNextDate, setIsLoadingNextDate] = useState<boolean>(false);
    //date
    const [nextDate, setNextDate] = useState<string | undefined>(undefined);

    //get next date on show component
    useEffect(() => {
        handleNextRunTime().then()
    }, []);

    //fetch next date or show error
    const handleNextRunTime = async () => {
        setIsLoadingNextDate(true)
        const response: Response = await getNextRunTime();
        if (response.ok) {
            const date: NextRunTime = await response.json()
            setNextDate(getDMYHMDate(date.next_run_time));
        } else {
            toast.error("Error la obteners la fecha próxima...")
        }
        setIsLoadingNextDate(false)
    }

    return (
        <>
            {/*show next date or skeleton*/}
            {
                isLoadingNextDate ? <NextDateSkeleton/> :
                    <div
                        className={'flex bg-white flex-row justify-between items-center w-full p-4 space-x-2 rounded-md shadow-md overflow-hidden'}>
                        <div className={'flex flex-row items-center space-x-2 whitespace-nowrap'}>
                            <h2 className="card-title">Fecha proxima:</h2>
                            <span>{nextDate == null ? '' : nextDate}</span>
                        </div>
                        <Clock className=" shrink-0"/>
                    </div>
            }
        </>)
}