import {apiClient} from "../utils/ApiClient";

/**
 * get app general logs
 */
export const getAppLogs = async (): Promise<Response> => {
    try {
        return await apiClient(
            `/logs`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    } catch (error) {
        throw error;
    }
};