import {apiClient} from "../utils/ApiClient";

/**
 * upload loses file
 * @param file
 * @param fee
 */
export const uploadLosesFileRaw = async (fee: any, file: File): Promise<Response> => {
    try {
        return await apiClient(`/upload-lose?tarifa=${fee}&name=${file.name}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            body: file
        });
    } catch (error) {
        throw error;
    }
};

export const getLoses = async (fee: any, startDate: any, endDate: any): Promise<Response> => {
    try {
        return await apiClient(
            `/loses?tarifa=${fee}&start_date=${startDate}&end_date=${endDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    } catch (error) {
        throw error;
    }
};
