import {create} from 'zustand';
import {ILoss} from "../models/i.loss";
import {getLoses} from "../services/LosesService";

export type LosesState = {
    loses: ILoss[];
    isLoading: boolean;
    error?: string;
    fetchLoses: (fee: string, startDate: string, endDate: string) => Promise<void>;
    resetValues: () => void
    isDatesSelected: boolean;
}

/**
 * store all profiles with fetch request, error and loading state
 */
export const useLosesStore = create<LosesState>((set) => ({
    loses: [],
    isLoading: false,
    error: undefined,
    isDatesSelected: false,
    resetValues: () => set({loses: [], isDatesSelected: false}),
    fetchLoses: async (fee: string, startDate: string, endDate: string) => {
        set({isLoading: true, error: undefined, isDatesSelected: true})
        try {
            const response = await getLoses(fee, startDate, endDate)
            if (response.ok) {
                const loses: ILoss[] = await response.json()
                set({isLoading: false, loses})
            } else {
                set({isLoading: false, error: 'Error al obtener las pérdidas...', loses: []})
            }
        } catch (error: any) {
            set({isLoading: false, error: error.message || 'Error getting loses...'})
        }
    }
}))