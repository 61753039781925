import {apiClient} from "../utils/ApiClient";

export interface LoginResponse {
    access_token: string
    token_type: string
}

interface LoginData {
    username: string
    password: string
}

/**
 * login user with username and password
 * @param loginData
 */
export const loginUser = async (loginData: LoginData): Promise<Response> => {
    try {
        return await apiClient(
            `/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginData),
            })
    } catch (error) {
        throw error;
    }
};
