//dynamic table skeleton
export default function TableSkeleton({numColumns, numRows}: { numColumns: number, numRows: number }) {
    return (
        <div className="skeleton-card relative flex-col h-full overflow-x-auto space-y-4 pb-6">

            <div className="w-full text-sm text-left rtl:text-right text-gray-500">
                <div className="sticky top-0 bg-blue-50">
                    <div>
                        <div className="flex flex-row w-full justify-between bg-green-50 p-4">

                            {Array.from({length: numColumns}).map((c, index) =>
                                <div key={index} className="bg-gray-400 rounded-full
                                 odd:w-32 even:w-24 xl:odd:w-64 xl:even:w-32
                                 h-6 mx-6 transition-all duration-500"></div>
                            )}

                        </div>
                    </div>
                </div>

                <div>

                    {Array.from({length: numRows}).map((r, index) =>

                        <div key={index} className="flex flex-row w-full justify-between px-4 py-4 first:mt-4">

                            {Array.from({length: numColumns}).map((c, subIndex) =>
                                <div key={subIndex} className="px-6 bg-gray-200 mx-6 rounded-full
                                                   odd:w-32 even:w-24 xl:odd:w-64 xl:even:w-32
                                                    h-6 transition-all duration-500"></div>
                            )}

                        </div>)}

                </div>
            </div>

        </div>

    )
}