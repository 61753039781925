import React from "react";
import {DateValue, getLocalTimeZone} from "@internationalized/date";
import {toast} from "react-toastify";
import {convertToDateValue, toLocalISOString} from "../../utils/date-utils";
import {DatePicker, Select, SelectItem} from "@nextui-org/react";
import {useLosesStore} from "../../store/LosesStore";

export const fees = [
    {title: "2.0 TD", value: "18"},
    {title: "3.0 TD", value: "19"},
    {title: "6.1 TD", value: "20"},
    {title: "6.2 TD", value: "21"},
    {title: "6.3 TD", value: "22"},
    {title: "6.4 TD", value: "23"},
    {title: "KEstimado", value: "67"}
]

export default function LosesFilters() {
    //fetch profiles method to zustand store
    const {fetchLoses} = useLosesStore();
    //start and end selected dates
    const [startDate, setStartDate] = React.useState<DateValue | undefined>(undefined)
    const [endDate, setEndDate] = React.useState<DateValue | undefined>(undefined)
    // Fee selection state

    const [selectedFee, setSelectedFee] = React.useState<string>("");

    const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => setSelectedFee(e.target.value);

    //call this when start and end dates changes and fetch profiles or show error message
    React.useEffect(() => {
        if (startDate && endDate && selectedFee.trim().length > 0) {
            const start = startDate.toDate(getLocalTimeZone());
            const end = endDate.toDate(getLocalTimeZone());

            if (end < start) {
                toast.error("La fecha de fin no puede ser inferior a la fecha de inicio.");
                return;
            }

            if (start > end) {
                toast.error("La fecha de inicio no puede ser mayor a la fecha de fin.");
                return;
            }
            fetchLoses(selectedFee, toLocalISOString(start), toLocalISOString(end)).then();
        }
    }, [startDate, endDate, selectedFee]);

    //set today date y default
    React.useEffect(() => {
        const now = new Date();
        // Create a new date for last month
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);

        setStartDate(convertToDateValue(lastMonth));
        setEndDate(convertToDateValue(now));
    }, []);

    return (
        <div className={'flex flex-row items-center space-x-2 flex-1'}>
            <DatePicker label="Fecha inicio" isRequired className="max-w-[184px]"
                        value={startDate} onChange={setStartDate}/>
            <DatePicker label="Fecha fin" isRequired className="max-w-[184px]"
                        value={endDate} onChange={setEndDate}/>
            <Select
                className="max-w-[184px]"
                label="Tarifas"
                placeholder="Selecciona la tarifa"
                selectedKeys={[selectedFee]}
                onChange={handleSelectionChange}>
                {fees.map((fee) => (
                    <SelectItem key={fee.value}>{fee.title}</SelectItem>
                ))}
            </Select>
        </div>
    )
}