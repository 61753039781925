import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/dropdown";
import {List, LogOut, Logs} from "lucide-react";
import {useAuth} from "../../utils/auth/AuthContext";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import {Button} from "@nextui-org/react";
import AppLogsDialog from "../shared/AppLogsDialog";

export default function MenuButton() {
    //logout and remove session
    const {logout} = useAuth();
    //to navigate to login screen
    const navigate = useNavigate();
    //to show-hide app session modal
    const [isSessionModalOpen, setSessionModalOpen] = useState(false);
    //to show-hide app logs modal
    const [isAppLogsModalOpen, setAppLogsOpen] = useState(false);

    //close session and navigate to login screen
    const handleLogout = () => {
        logout()
        setSessionModalOpen(false)
        navigate('/login', {replace: true});
    };

    return (
        <>

            {/**Show app logs dialog*/}
            {isAppLogsModalOpen && (
                <AppLogsDialog
                    isOpen={isAppLogsModalOpen}
                    onClose={() => setAppLogsOpen(false)}
                />
            )}

            {/**close session dialog*/}
            {isSessionModalOpen && (
                <ConfirmationDialog
                    confirmationText={"Sí"}
                    confirmationColor={"primary"}
                    isOpen={isSessionModalOpen}
                    onClose={() => setSessionModalOpen(false)}
                    onConfirm={() => handleLogout()}
                    title={"Sesión"}
                    message={"¿Desea cerrar sesión?"}
                />
            )}
            {/**Home dropdown menu*/}
            <Dropdown>
                <DropdownTrigger>

                    <Button startContent={<List/>} variant={'light'} isIconOnly color={'primary'}></Button>

                </DropdownTrigger>
                <DropdownMenu aria-label="Actions">
                    <DropdownItem key="logs"
                                  startContent={<Logs size={'16'}/>}
                                  onClick={() => setAppLogsOpen(true)}>
                        Logs
                    </DropdownItem>
                    <DropdownItem key="exit"
                                  className="text-danger"
                                  color="danger"
                                  startContent={<LogOut size={'16'}/>}
                                  onClick={() => setSessionModalOpen(true)}>
                        Cerrar sesión
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    )
}