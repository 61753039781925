import {apiClient} from "../utils/ApiClient";
import {CustomError} from "../models/custom-error";

export interface TasksData {
    page: number
    limit: number
}

export interface NextRunTime {
    next_run_time: string
}

export interface StopTaskResult {
    message: string
}

export interface ErrorTask {
    detail: string
}

/**
 * get task by id
 * @param taskId
 */
export const getTask = async (taskId: string): Promise<Response> => {
    try {
        return await apiClient(
            `/tasks/${taskId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    } catch (error) {
        throw error;
    }
};

/**
 * get list tasks with page and limit
 * @param tasksData
 */
export const getTasks = async (tasksData: TasksData): Promise<Response> => {
    try {
        return await apiClient(
            `/tasks?page=${tasksData.page}&limit=${tasksData.limit}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    } catch (error) {
        throw error;
    }
};

/**
 * next run time date
 */
export const getNextRunTime = async (): Promise<Response> => {
    try {
        return await apiClient(
            `/next_run_time`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    } catch (error) {
        throw error;
    }
};

/**
 * stop current task
 */
export const stopTask = async (): Promise<Response> => {
    try {
        return await apiClient(
            `/stop`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    } catch (error) {
        throw error;
    }
};

/**
 * create new task
 */
export const startTask = async (): Promise<Response> => {
    try {
        return apiClient(
            `/tasks`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async (response) => {

            if (!response.ok) {
                const result: ErrorTask = await response.json()
                if (result.detail) {
                    throw new CustomError(result.detail);
                } else {
                    throw new CustomError("Error al iniciar la tarea.");
                }
            }

            return response;
        });
    } catch (error) {
        throw error;
    }
};
