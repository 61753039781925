/**
 * convert enum task status into string
 * @param statusRaw
 */
export function getTaskStatus(statusRaw: string): string {
    let status = '';
    switch (statusRaw) {
        case 'queued': {
            status = 'Cola'
            break;
        }
        case 'in_progress': {
            status = 'En progreso'
            break;
        }
        case 'completed': {
            status = 'Completado'
            break;
        }
        case 'error': {
            status = 'Error'
            break;
        }
    }
    return status;
}

/**
 * convert enum task type into string
 * @param typeRaw
 */
export function getTaskType(typeRaw: string): string {
    let status = '';
    switch (typeRaw) {
        case 'manual': {
            status = 'Manual'
            break;
        }
        case 'automatic': {
            status = 'Automático'
            break;
        }
    }
    return status;
}