import React from 'react';
import TableSkeleton from "../skeletons/TableSkeleton";
import {useLosesStore} from "../../store/LosesStore";

export default function TableLoses() {

    const {loses, isLoading} = useLosesStore();

    return (
        <>
            {/**show skeleton or tasks table*/}
            {
                isLoading ? <TableSkeleton numColumns={3} numRows={3}/> :

                    <div className={'flex flex-col space-y-4'}>
                        <table className="table">
                            <thead>
                            <tr className="table-tr">
                                <th className="table-th rounded-tl-lg">Fecha</th>
                                <th className="table-th">Hora</th>
                                <th className="table-th rounded-tr-lg">Valor</th>
                            </tr>
                            </thead>
                            {!isLoading && loses.length > 0 &&
                                <tbody>
                                {loses.map((loss, index) => (
                                    <tr key={index}>
                                        <td className="table-td">{loss.fecha}</td>
                                        <td className="table-td">{loss.hora_dia}</td>
                                        <td className="table-td">{loss.valor}</td>
                                    </tr>
                                ))}
                                </tbody>
                            }
                        </table>
                        {/**show when has no data*/}
                        {!isLoading && loses.length === 0 && <div>
                            <p className={'flex items-center justify-center font-semibold text-slate-400'}>Sin datos</p>
                        </div>}
                    </div>
            }
        </>
    );
};


