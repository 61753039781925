import React from 'react';
import {Outlet} from 'react-router-dom';
import ToolBar from "../shared/ToolBar";

const Layout: React.FC = () => {
    return (
        <div>
            <ToolBar/>
            <main><Outlet/></main>
        </div>
    );
};

export default Layout;
