import {create} from 'zustand';
import {IProfile} from "../models/i.profile";
import {getProfiles} from "../services/ProfilesService";

export type ProfileState = {
    profiles: IProfile[];
    isLoading: boolean;
    error?: string;
    fetchProfiles: (startDate: string, endDate: string) => Promise<void>;
    resetValues: () => void
    isDatesSelected: boolean;
}

/**
 * store all profiles with fetch request, error and loading state
 */
export const useProfileStore = create<ProfileState>((set) => ({
    profiles: [],
    isLoading: false,
    error: undefined,
    isDatesSelected: false,
    resetValues: () => set({profiles: [], isDatesSelected: false}),
    fetchProfiles: async (startDate: string, endDate: string) => {
        set({isLoading: true, error: undefined, isDatesSelected: true})
        try {
            const response = await getProfiles(startDate, endDate)
            if (response.ok) {
                const profiles: IProfile[] = await response.json()
                set({isLoading: false, profiles})
            } else {
                set({isLoading: false, error: 'Error al obtener los perfiles...', profiles: []})
            }
        } catch (error: any) {
            set({isLoading: false, error: error.message || 'Error getting profiles...'})
        }
    }
}))