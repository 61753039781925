/**
 * api client with bearer token, endpoint and other options
 * @param endpoint
 * @param options
 */
export const apiClient = async (
    endpoint: string,
    options: RequestInit = {}
): Promise<Response> => {
    const token = localStorage.getItem("accessToken");

    const config: RequestInit = {
        ...options,
        headers: {
            "Content-Type": "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...options.headers,
        },
    };

    const base_url =
        process.env.REACT_APP_BASE_PROTOCOL +
        "://" +
        process.env.REACT_APP_BASE_URL +
        (process.env.REACT_APP_BASE_PORT
            ? ":" + process.env.REACT_APP_BASE_PORT
            : "") +
        (process.env.REACT_APP_BASE_API_PREFIX
            ? "/" + process.env.REACT_APP_BASE_API_PREFIX
            : "");

    return await fetch(`${base_url}${endpoint}`, config);
    //return await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, config);
};
