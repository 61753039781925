import {apiClient} from "../utils/ApiClient";

/**
 * download template into "Downloads" folder
 */
export const downloadTemplate = async (): Promise<Response> => {
    try {
        return await apiClient(
            `/download-template`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    } catch (error) {
        throw error;
    }
};

/**
 * upload profile file
 * @param file
 */
export const uploadFileRaw = async (file: File): Promise<Response> => {
    try {
        return await apiClient(`/upload-profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
            body: file, // Send the raw binary data
        });
    } catch (error) {
        throw error;
    }
};

/**
 * get profiles by range dates
 * @param startDate
 * @param endDate
 */
export const getProfiles = async (startDate: any, endDate: any): Promise<Response> => {
    try {
        return await apiClient(
            `/profile?start_date=${startDate}&end_date=${endDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    } catch (error) {
        throw error;
    }
};
