import {formatInTimeZone, toZonedTime} from 'date-fns-tz';
import {CalendarDate} from "@internationalized/date";

/**
 * calculate different hours between start and end date and format it to 00h:00m
 * @param dateStart
 * @param dateEnd
 */
export const calculateDatesHrs = (dateStart: string, dateEnd: string): string => {
    const start = new Date(dateStart);
    const end = new Date(dateEnd);

    const difMilliseconds = Math.abs(end.getTime() - start.getTime());

    const h = Math.floor(difMilliseconds / (1000 * 60 * 60));
    const m = Math.floor((difMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return `${h.toString().padStart(2, '0')}h:${m.toString().padStart(2, '0')}m`;
};

/**
 * format date int /day/month/year hour/minutes
 * @param dateString
 */
export const getDMYHMDate = (dateString: string): string => {
    const timeZone = 'Europe/Madrid';
    const zonedDate = toZonedTime(dateString, timeZone);
    return formatInTimeZone(zonedDate, timeZone, 'dd/MM/yyyy HH:mm');
};

/**
 * format date into iso date
 * @param date
 */
export const toLocalISOString = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export function convertToDateValue(date: Date): CalendarDate {
    return new CalendarDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
    );
}