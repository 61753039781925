import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@nextui-org/modal";
import {Button} from "@nextui-org/react";

interface ConfirmationProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    confirmationText?: string;
    confirmationColor?: any;
}

//customizable dialog
export default function ConfirmationDialog(
    {isOpen, onClose, onConfirm, title, message, confirmationText, confirmationColor}: ConfirmationProps) {
    return (
        <Modal
            isDismissable={false}
            isOpen={isOpen}
            onOpenChange={onClose}>
            <ModalContent className={`sm:max-w-[425px] min-w-[600px]`}>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">{title}</ModalHeader>
                        <ModalBody>
                            <p>{message}</p>
                        </ModalBody>
                        <ModalFooter className={`justify-between`}>
                            <Button variant='flat' onPress={onClose}>Cancelar</Button>
                            <Button
                                color={confirmationColor ?? 'danger'}
                                onPress={onConfirm}>
                                {confirmationText ?? 'Eliminar'}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}