import {Save} from "lucide-react";
import {Button} from "@nextui-org/react";
import React from "react";
import {useProfileStore} from "../../store/ProfileStore";
import {IProfile} from "../../models/i.profile";

export default function SaveCVSButton() {
    //access profiles from zustand store
    const {profiles} = useProfileStore();

    //convert profiles to csv with headers
    const convertToCSV = (data: IProfile[]) => {
        // Get the headers from the first object
        const headers = Object.keys(data[0]) as (keyof IProfile)[]; // Assert that the keys are valid keys of IProfile
        const transformedHeaders = headers.map(header =>
            header.replace(/_/g, ' ').toUpperCase()
        );
        // Create the CSV content
        const csvRows = [
            transformedHeaders.join(','), // header row
            ...data.map(row =>
                headers.map(fieldName => JSON.stringify(row[fieldName])).join(',') // Accessing properties safely
            )
        ];

        // Join the rows to create the CSV content
        return csvRows.join('\n');
    };

    //save file into pc
    const saveCSV = () => {
        if (profiles.length > 0) {
            const csv = convertToCSV(profiles);
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'profile_data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Button startContent={<Save className={'shrink-0'}/>}
                color={'primary'}
                onClick={() => saveCSV()}>Guardar CSV</Button>
    )
}