//next run time date card skeleton
export default function NextDateSkeleton() {
    return (
        <div
            className="skeleton-card flex-row justify-between items-center p-4">
            <div className="skeleton-row space-x-2">
                <h2 className="skeleton-text w-[122px] h-6"></h2>
                <span className="skeleton-text w-[84px] h-6"></span>
            </div>
            <div className="skeleton-text w-6 h-6"></div>
        </div>
    )
}