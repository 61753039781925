import {Button} from "@nextui-org/react";
import {ChevronLeft, ChevronRight} from "lucide-react";
import {usePaginationStore} from "../../store/PaginationStore";

export default function Pagination() {
    //pagination zustand store
    const {page, hideButtonNextPage, setPage} = usePaginationStore()

    //navigate to next-previous page
    const handlePage = (isNextPage: boolean = false) => {
        let newPage = isNextPage ? page + 1 : page > 1 ? page - 1 : 1;
        setPage(newPage);
    }

    return (
        <div className="flex flex-row items-center justify-center space-x-2">
            {/*navigate to previous page*/}
            <Button isIconOnly
                    color={page === 1 ? 'default' : 'primary'}
                    className={`${page === 1 ? 'opacity-20 cursor-default' : 'opacity-100'}`}
                    variant={'light'}
                    disabled={page === 1}
                    startContent={<ChevronLeft/>}
                    onClick={() => handlePage()}/>
            {/*navigate to page 1 when current page it's > 2*/}
            {page > 2 &&
                <Button isIconOnly color={'primary'} variant={'flat'} onClick={() => setPage(1)}>1</Button>}
            {/*show dots when current page it's > 2*/}
            {page > 2 && <span>...</span>}
            <Button isIconOnly color={'primary'} variant={'flat'} disabled>{page}</Button>
            {/*navigate to next page*/}
            <Button isIconOnly
                    color={hideButtonNextPage ? 'default' : 'primary'}
                    className={`${hideButtonNextPage ? 'opacity-20 cursor-default' : 'opacity-100'}`}
                    variant={'light'}
                    disabled={hideButtonNextPage}
                    startContent={<ChevronRight/>}
                    onClick={() => handlePage(true)}/>
        </div>
    )
}