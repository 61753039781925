import {ArrowLeft, TriangleAlert} from "lucide-react";
import {Button} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import React from "react";

export default function NotFoundPage() {
    //to navigate to home screen
    const navigate = useNavigate();
    return (
        <>
            {/*show items centered*/}
            <div className={'flex flex-col items-center justify-center h-svh space-y-12'}>
                <div className={'flex flex-col items-center space-y-2'}>
                    <TriangleAlert size={86}/>
                    <span className={'tracking-tight font-semibold text-slate-400'}>404 | Página no encontrada</span>
                </div>
                {/*button to navigate to home screen*/}
                <Button startContent={<ArrowLeft/>}
                        variant={'light'}
                        color={'primary'}
                        onClick={() => navigate('/', {replace: true})}>Volver al inicio</Button>
            </div>
        </>

    )
}