import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthProvider} from "./utils/auth/AuthContext";
import {NextUIProvider} from "@nextui-org/react";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import AppRoutes from "./routes";
import './styles/skeleton.css'
import './styles/import-status.css'
import './styles/table.css'
import './styles/toolbar.css'
import './styles/txt-input.css'

function App() {
    return (
        <>
            <ToastContainer
                autoClose={4000}
                progressStyle={{
                    background: '#438581'
                }}/>
            <NextUIProvider>
                <AuthProvider>
                    <Router>
                        <AppRoutes/>
                    </Router>
                </AuthProvider>
            </NextUIProvider>
        </>
    );
}

export default App;
