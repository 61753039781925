import {create} from 'zustand';
import {ITask} from "../models/i.task";
import {getTasks, TasksData} from "../services/TaskService";

export type TasksState = {
    tasks: ITask[];
    isLoading: boolean;
    error?: string;
    fetchTasks: (tasksData: TasksData) => Promise<void>;
}

/**
 * store all tasks with fetch request, error and loading state
 */
export const useTasksStore = create<TasksState>((set) => ({
    tasks: [],
    isLoading: false,
    error: undefined,
    fetchTasks: async (tasksData: TasksData) => {
        set({isLoading: true, error: undefined})
        try {
            const response = await getTasks(tasksData)
            if (response.ok) {
                const tasks: ITask[] = await response.json()
                set({isLoading: false, tasks})
            } else {
                set({isLoading: false, error: 'Error al obtener las tareas...', tasks: []})
            }
        } catch (error: any) {
            set({isLoading: false, error: error.message || 'Error getting tasks...'})
        }
    }
}))