import {Download} from "lucide-react";
import {Button} from "@nextui-org/react";
import React from "react";
import {downloadTemplate} from "../../services/ProfilesService";
import {toast} from "react-toastify";

export default function DownloadTemplateButton() {
    //downloading state
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false)

    //download excel template into downloads files
    const handleDownloadTemplate = async () => {
        setIsDownloading(true)
        const response: Response = await downloadTemplate();
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Plantilla.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            toast.error("Error al descargar la plantilla...")
        }
        setIsDownloading(false)
    }
    return (
        <Button startContent={isDownloading ? null : <Download/>}
                color={'primary'}
                variant={'light'}
                isLoading={isDownloading}
                isDisabled={isDownloading}
                onClick={() => handleDownloadTemplate()}>Plantilla</Button>
    )
}